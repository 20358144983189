import { useEffect, useState } from 'react';

/**
 * Event fired for each adsense placement loaded.
 * The detail of the event contains the containerName and the placement status.
 */
const ADSENSE_LOAD_EVENT: string = 'subito:adsense:load';
export type AdSenseLoadEvent = CustomEvent<{
  containerName: string;
  isEmpty: boolean;
}>;

function isAdSensLoadEvent(e: Event): e is AdSenseLoadEvent {
  return (
    e instanceof CustomEvent &&
    'containerName' in e.detail &&
    'isEmpty' in e.detail
  );
}

/**
 * Dispatches the event with the status of the loaded adSense placement
 */
const notifyAdSenseLoadStatus = (
  containerName: string,
  isEmpty: boolean
): void => {
  window.dispatchEvent(
    new CustomEvent(ADSENSE_LOAD_EVENT, {
      detail: { containerName, isEmpty },
    })
  );
};

/**
 * Event fired when we ask to adSense to fill our placements.
 */
const ADSENSE_RESET_EVENT: string = 'subito:adsense:reset';

/**
 * Dispatches a "reset" event which is called when we ask to adSense
 * to fill our placements.
 */
const notifyAdSenseReset = (): void => {
  window.dispatchEvent(new Event(ADSENSE_RESET_EVENT));
};

/**
 * This is an event based hook which keep the isEmpty status updated for the
 * given placement.
 */
const useIsEmptyAdSense = (placementId: string): boolean | undefined => {
  const [isEmpty, setIsEmpty] = useState<boolean | undefined>();

  useEffect(() => {
    const onEmptyPlacement = function (e: Event) {
      if (isAdSensLoadEvent(e) && e.detail.containerName === placementId) {
        setIsEmpty(e.detail.isEmpty);
      }
    };
    const onReset = function () {
      setIsEmpty(undefined);
    };

    const dispose = () => {
      window.removeEventListener(ADSENSE_LOAD_EVENT, onEmptyPlacement);
      window.removeEventListener(ADSENSE_RESET_EVENT, onReset);
    };

    window.addEventListener(ADSENSE_LOAD_EVENT, onEmptyPlacement);
    window.addEventListener(ADSENSE_RESET_EVENT, onReset);
    return dispose;
  }, [placementId]);

  return isEmpty;
};

export {
  ADSENSE_LOAD_EVENT,
  ADSENSE_RESET_EVENT,
  notifyAdSenseLoadStatus,
  notifyAdSenseReset,
  useIsEmptyAdSense,
};
