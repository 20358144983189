import { RootState } from '@reducers/index';
import { setPreventPopstate } from '@reducers/system';
import { setDialogInfo, UserState } from '@reducers/user';
import {
  FavoritesError,
  FavoritesErrorPayload,
  FavoritesProvider,
} from '@sbt-web/favorites';
import { HTTPStatusCode } from '@sbt-web/networking';
import { assertNever } from '@sbt-web/utils';
import { LoginTemplate } from '@sbt-web/auth';
import { sendNotification } from '@shared/tools/Notifications';
import React from 'react';
import { connect } from 'react-redux';
import { getDataLayer, getItem, getOrCreatePulse } from '@tools/tracking/utils';
import { HADES_PATH } from '@shared/constants';

interface FCStateProps {
  user: UserState;
}

interface FCDispatchProps {
  requestLogin: (cb: () => void) => void;
}

export interface FavProps {
  onUnsave?: (urn: string) => void;
  onSave?: (urn: string) => void;
  onEngage?: (urn: string) => void;
  children: React.ReactNode;
}

type FCProps = FCStateProps & FCDispatchProps & FavProps;

export class FavoritesContainer extends React.Component<FCProps> {
  checkUnauthRequestLogin = (
    data: FavoritesErrorPayload,
    cb?: () => void
  ): boolean => {
    if (
      !this.props.user.logged ||
      (!(data instanceof Error) &&
        data.response?.payload.code === HTTPStatusCode.Unauthorized)
    ) {
      this.props.requestLogin(() => {
        if (data.urn && getItem(getDataLayer(), data.urn)) {
          getOrCreatePulse()?.queueEvent({
            type: 'Login',
            object: {
              id: 'login-inplace',
              type: 'Confirmation',
              items: getItem(getDataLayer(), data.urn),
            },
            target: {
              id: 'login-inplace',
              type: 'Confirmation',
            },
            origin: {
              description: 'view-form-save-classified-ad',
            },
          });
        } else {
          if (!data.urn) {
            throw new Error('"adUrn" not found and is required');
          } else if (!getItem(getDataLayer(), data.urn)) {
            throw new Error(
              `Ad with urn ${data.urn} not found in dataLayer ads`
            );
          }
        }

        cb?.();
      });
      return false;
    }
    return true;
  };

  handleFavoritesError = (
    err: FavoritesError,
    data: FavoritesErrorPayload,
    retrier?: () => void
  ) => {
    switch (err) {
      case FavoritesError.Save:
        return (
          this.checkUnauthRequestLogin(data, retrier) &&
          sendNotification(
            "Impossibile aggiungere l'annuncio ai preferiti",
            'alert'
          )
        );
      case FavoritesError.Unsave:
        return (
          this.checkUnauthRequestLogin(data, retrier) &&
          sendNotification(
            "Impossibile rimuovere l'annuncio dai preferiti",
            'alert'
          )
        );
      case FavoritesError.Sync:
      case FavoritesError.Unknown:
        // We don't want to explicitly warn the user about this error
        return;
      default:
        assertNever(err);
    }
  };

  render() {
    const { user, children, onUnsave, onSave, onEngage } = this.props;
    return (
      <FavoritesProvider
        APIBaseUrl={HADES_PATH}
        APIChannel="web"
        onUnsave={onUnsave}
        onSave={onSave}
        onError={this.handleFavoritesError}
        onEngage={onEngage}
        userId={user.data ? user.data.id : ''}
      >
        {children}
      </FavoritesProvider>
    );
  }
}

export default connect<FCStateProps, FCDispatchProps, FavProps, RootState>(
  (state: RootState, ownProps: FavProps) => ({
    user: state.user,
    ...ownProps,
  }),
  (dispatch) => ({
    requestLogin: (cb: () => void) => {
      dispatch(setDialogInfo(true, LoginTemplate.Favorites, cb));
      dispatch(setPreventPopstate(true));
    },
  })
)(FavoritesContainer);
