import type { AdItem, ItemFeature } from '@sbt-web/networking';
import { mapPackToItemFeatures } from '@shared/helpers/PackFeatures';
import { getFiltersConfigStore } from '../search/filtersParser';
import { getErrorMessage } from '@tools/errorHelpers';

/**
 * Map Subito listing filters with Hebe filters
 * @param category
 * @param ad_type
 * @param filters
 */
export function parseListingFiltersForHebe(
  category: string, // category_id
  ad_type: string,
  filters: Record<string, string>
): { key: string; values: Array<string> }[] {
  try {
    const config = getFiltersConfigStore(category, ad_type)?.sparseFilters;

    if (config != null) {
      // Create the filters metadata object for hebe
      const hebeFilters: Record<string, string | Array<string>> = {};
      config.forEach((filter) => {
        if (filters[filter.queryString])
          if ('multiSelection' in filter || filter.queryString.includes(','))
            // Use array for multi-selection value
            hebeFilters[filter.uri] = filters[filter.queryString].split(',');
          else hebeFilters[filter.uri] = filters[filter.queryString];
      });

      return cleanFilters(hebeFilters);
    } else {
      return [];
    }
  } catch (error) {
    console.error(
      `Ancillary Services Listing: An error occurred while parsing hebe filters -`,
      `categoryId: ${category} - adType: ${ad_type}`,
      getErrorMessage(error)
    );
    return [];
  }
}

/**
 * Map Subito Ad Detail features with Hebe filters
 * @param item
 */
export function parseAdDetailFeaturesForHebe(
  item: AdItem
): { key: string; values: Array<string> }[] {
  const features: { [p: string]: ItemFeature } = mapPackToItemFeatures(
    item.features
  );
  try {
    // Create the filters metadata object for hebe
    const hebeFilters: Record<string, string | Array<string>> = {};
    Object.keys(features).forEach((key) => {
      if (features[key]?.type !== 'pack') {
        if (features[key]?.values?.length === 1)
          hebeFilters[key] = features[key]?.values[0]?.key;
        // Use array for multi-selection value
        else hebeFilters[key] = features[key]?.values.map((value) => value.key);
      }
    });
    // Add advertiser_type
    if (item?.advertiser?.type !== null)
      hebeFilters['/advertiser_type'] = String(item.advertiser.type);

    return cleanFilters(hebeFilters);
  } catch (error) {
    console.error(
      `Ancillary Services AdDetail: An error occurred while parsing hebe features -`,
      `url ${item.urls.default}`,
      getErrorMessage(error)
    );
    return [];
  }
}

function cleanFilters(
  hebeFilters: Record<string, string | Array<string>>
): { key: string; values: Array<string> }[] {
  return Object.keys(hebeFilters)
    .filter((key) => hebeFilters[key] !== null)
    .map((key) => {
      const filters = hebeFilters[key];
      return {
        key,
        values: typeof filters === 'string' ? filters.split(',') : filters,
      };
    });
}
