import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';
import type { RootState } from '@reducers/index';
import type { SearchState } from '@reducers/search';
import { getStore } from '@reducers/store';
import {
  AdTypes,
  CategoryStore,
  isCategory,
  type AdItem,
  type BaseCategory,
  type GeoEntry,
  type ItemAdvertiser,
  type ItemCategory,
  type ItemFeature,
  type KeyValuePair,
} from '@sbt-web/networking';
import { sendEventToGTM, sendInitEventToGTM } from '@sbt-web/tracking';
import { captureException } from '@sentry/nextjs';
import { mapPackToItemFeatures } from '@shared/helpers/PackFeatures';
import { extractAdItems, type DecoratedItem } from '@shared/models';
import { getFiltersConfigStore } from '@tools/search';
import {
  adTypesLabelsMap,
  FilterURI,
  ordersFullLabelsMap,
  QSOLabel,
  shippableOnlyLabel,
  urgentLabel,
} from '@tools/search/values';
import { onDataLayerReady } from '@tools/tracking/utils';
import { getUrnRank } from './navigation-data';

interface DLBaseContent {
  id: string;
  name: string;
}

export enum DLsubscription {
  PRO = 'pro',
  FREEMIUM = 'freemium',
}

export interface DLAdvertiser {
  id: string;
  type: string;
  shop_id: string;
  subscription: DLsubscription;
}

interface DLCategory {
  child: DLBaseContent;
  parent: DLBaseContent;
}

interface DLGeo {
  region?: DLBaseContent;
  city?: DLBaseContent;
  town?: DLBaseContent;
}

export interface DLAdType {
  id: string;
  name: string;
  weight?: number;
}

interface DLAd {
  urn: string;
  type: DLAdType | null;
  subject: string;
  geo: DLGeo;
  advertiser: DLAdvertiser;
  category: DLCategory;
  features: { [key: string]: DLBaseContent };
  rank?: number | null;
}

interface DLSearch {
  id: string;
  category: DLCategory;
  adType: DLBaseContent;
  filters: { [key: string]: DLBaseContent };
  geo: DLGeo;
  query: string;
  sort: DLBaseContent;
  page: number;
}

export interface DataLayer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  push(data: any): void;
  clearAds(): void;
  subscribe(modelName: string, cb: () => void): void;
  unsubscribe(modelName: string, cb: () => void): void;
  ads: {
    totalResults: string;
  } & DLAd[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const getDLItemCategory = (cat: ItemCategory): DLCategory => {
  const { label: macroLabel } = CategoryStore.getCategoryById(cat.parentId);
  return {
    child: {
      id: cat.id,
      name: cat.label,
    },
    parent: {
      id: cat.parentId,
      name: macroLabel,
    },
  };
};

const getDLFeatures = (features: {
  [key: string]: ItemFeature;
}): Record<string, DLBaseContent> => {
  const featuresWithPack: {
    [key: string]: ItemFeature;
  } = mapPackToItemFeatures(features);

  return Object.keys(featuresWithPack).reduce(
    (acc, key) => {
      try {
        const newFeat: DLBaseContent = {
          id: featuresWithPack[key].values[0].key,
          name: featuresWithPack[key].values[0].value,
        };
        acc[key] = newFeat;
      } catch (e) {
        captureException(
          new Error(
            `Error in processing the feature ${key}: ${(e as Error).message}`
          )
        );
      }
      return acc;
    },
    {} as Record<string, DLBaseContent>
  );
};

const getDLGeo = (geo: GeoEntry): DLGeo => {
  const dlGeo: DLGeo = {
    region: { id: geo.region.id, name: geo.region.value },
  };
  if (geo.city) {
    dlGeo.city = { id: geo.city?.id, name: geo.city?.value };
  }
  if (geo.town) {
    dlGeo.town = { id: geo.town?.id, name: geo.town?.value };
  }
  return dlGeo;
};

const getDLAdType = (type: KeyValuePair | null): DLAdType | null => {
  if (!type) {
    return null;
  }

  const retType = {
    id: type.key,
    name: type.value,
  };

  if (type.weight) {
    return {
      ...retType,
      weight: type.weight,
    };
  }

  return retType;
};

const getDLadvertiser = (advertiser: ItemAdvertiser): DLAdvertiser => {
  const subscription =
    advertiser.type === 0 ? DLsubscription.FREEMIUM : DLsubscription.PRO;

  return {
    id: advertiser.userId,
    type: advertiser.type.toString(),
    shop_id: advertiser.shopId || '',
    subscription,
  };
};

const getDLAds = (items: DecoratedItem[]): DLAd[] =>
  extractAdItems(items).map((item) => ({
    advertiser: getDLadvertiser(item.advertiser),
    type: getDLAdType(item.type),
    category: getDLItemCategory(item.category),
    features: getDLFeatures(item.features),
    geo: getDLGeo(item.geo),
    subject: item.subject,
    urn: item.urn,
  }));

const getDLdetailAd = (item: AdItem): DLAd => {
  const dlAd: DLAd = {
    urn: item.urn,
    type: getDLAdType(item.type),
    subject: item.subject,
    geo: getDLGeo(item.geo),
    advertiser: getDLadvertiser(item.advertiser),
    category: getDLItemCategory(item.category),
    features: getDLFeatures(item.features),
    rank: getUrnRank(item.urn),
  };

  return dlAd;
};

const getDLSearchCategory = (cat: BaseCategory): DLCategory => {
  const catData: DLBaseContent = { id: cat.id, name: cat.label };
  if (isCategory(cat)) {
    const macro = CategoryStore.getMacroByCategory(cat);
    if (macro === undefined) {
      return {
        parent: catData,
        child: catData,
      };
    } else {
      return {
        parent: { id: macro.id, name: macro.label },
        child: catData,
      };
    }
  } else {
    return {
      parent: catData,
      child: catData,
    };
  }
};

const getDLSearchFilters = (
  search: SearchState
): { [key: string]: DLBaseContent } => {
  const DLFilters: { [key: string]: DLBaseContent } = {};
  if (search.qso) {
    DLFilters[FilterURI.QSO] = {
      id: 'true',
      name: QSOLabel,
    };
  }
  if (search.includeShippableOnly) {
    DLFilters[FilterURI.ItemShippable] = {
      id: 'true',
      name: shippableOnlyLabel,
    };
  }
  if (search.includeUrgent) {
    DLFilters[FilterURI.ItemUrgent] = {
      id: 'true',
      name: urgentLabel,
    };
  }
  const config = getFiltersConfigStore(search.category.id, search.adType);
  if (!config) {
    return DLFilters;
  } else {
    const filters = config.sparseFilters;
    return Object.keys(search.filters).reduce((acc, key) => {
      const found = filters.find((f) => f.queryString === key);
      if (found) {
        acc[found.uri] = {
          id: search.filters[key],
          name: search.filtersLabels[key],
        };
      }
      return acc;
    }, DLFilters);
  }
};

class DataLayerBridge {
  #previousState: RootState | undefined;

  #page: 'listing' | 'adview';
  /**
   * The item displayed on the detail page.
   * It is no longer in the state, but I'm trying to make minimal changes.
   * On listing pages, this is null.
   */
  #detailItem: AdItem | null = null;

  constructor(page: 'listing' | 'adview', detailItem: AdItem | null = null) {
    this.#page = page;

    if (page === 'adview' && detailItem) {
      this.#detailItem = detailItem;
    }
  }

  get isListingPage(): boolean {
    return this.#page === 'listing';
  }

  get isAdDetailPage(): boolean {
    return this.#page === 'adview';
  }

  static #isSearchStateUpdated = (
    previousState: SearchState,
    state: SearchState
  ): boolean => {
    return (
      previousState !== state &&
      previousState.isUpdating === state.isUpdating &&
      previousState.filtersDialogOpen === state.filtersDialogOpen
    );
  };

  updateDataLayerUser(state: RootState): void {
    if (
      (!this.#previousState ||
        this.#previousState.user.data !== state.user.data) &&
      state.user.data
    ) {
      window.subito.dataLayer?.push({
        user: {
          id: state.user.data.id,
          email: state.user.data.email,
        },
      });
    }
  }

  updateDataLayerAdsListing(state: RootState): void {
    if (
      (!this.#previousState ||
        this.#previousState.items.list !== state.items.list) &&
      window.subito.dataLayer
    ) {
      window.subito.dataLayer.clearAds();
      window.subito.dataLayer.ads.totalResults = state.items.total.toString();
      window.subito.dataLayer.push({
        ads: getDLAds(state.items.list),
      });
    }
  }

  updateDataLayerAds(state: RootState): void {
    if (this.isListingPage) {
      this.updateDataLayerAdsListing(state);
    }
  }

  updateDataLayerSearch(state: RootState): void {
    if (
      this.isListingPage &&
      (!this.#previousState ||
        DataLayerBridge.#isSearchStateUpdated(
          this.#previousState.search,
          state.search
        ))
    ) {
      const search: DLSearch = {
        id: state.search.id ?? '',
        category: getDLSearchCategory(state.search.category),
        adType: {
          id: state.search.adType
            ? state.search.adType.toString()
            : AdTypes.Sell,
          name:
            adTypesLabelsMap[state.search.adType] ||
            adTypesLabelsMap[AdTypes.Sell],
        },
        filters: getDLSearchFilters(state.search),
        geo: getDLGeo(state.search.geo),
        query: state.search.query ?? '',
        sort: {
          id: state.search.category.orders[state.search.orderIndex],
          name: ordersFullLabelsMap[
            state.search.category.orders[state.search.orderIndex]
          ],
        },
        page: state.search.page,
      };

      window.subito.dataLayer?.push({ search });
    }
  }

  update(state: RootState): void {
    this.updateDataLayerUser(state);
    this.updateDataLayerSearch(state);
    this.updateDataLayerAds(state);
    this.#previousState = state;
    markPerformance(Marks.DATA_LAYER_IS_READY);
    onDataLayerReady();
  }

  /**
   * Prepares the data layer with the static information of the page and
   * environment, updates the data layer with the current state and subscribes
   * to the store to enable cascading updates.
   */
  register(): void {
    // One-time setup of the Ad Detail layer
    if (this.#page === 'adview' && window.subito.dataLayer) {
      window.subito.dataLayer.clearAds();
      window.subito.dataLayer.ads.totalResults = 1;

      if (this.#detailItem !== null) {
        window.subito.dataLayer.push({
          ads: [getDLdetailAd(this.#detailItem)],
        });
      }
    }

    window.subito.dataLayer?.push({
      env: {
        name: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT,
        cachebuster: '',
        tracking: {},
        application: 'orodha',
      },
      page: {
        name: document.title,
        page_type: this.#page,
      },
    });

    const store = getStore(undefined, false);

    const updateDataLayer = (): void => this.update(store.getState());

    // Run the first update manually and then update on every redux state change
    updateDataLayer();
    // This will run on search and item updates on the listing, and user updates on both pages
    store.subscribe(updateDataLayer);

    sendInitEventToGTM();
    sendEventToGTM('gtm.js', { 'gtm.start': new Date().getTime() });
  }
}

export { DataLayerBridge, getDLAdType, getDLadvertiser };
export type { DLAd, DLCategory, DLGeo };
