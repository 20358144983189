import type { DLGeo } from '@client/bridges/DataLayer';
import transformLocality from '@tools/tracking/transformer/LocalityTransformer';

interface PostalAddress {
  '@type': 'PostalAddress';
  addressCountry: 'Italia';
  addressRegion?: string;
  addressLocality?: string;
}

export default function transform(geo: DLGeo): PostalAddress {
  return {
    '@type': 'PostalAddress',
    addressCountry: 'Italia',
    addressRegion: geo.region?.name,
    addressLocality: transformLocality(geo),
  };
}
