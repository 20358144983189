import { AdvertiserType, type ItemAdvertiser } from '@sbt-web/networking';
import { type DLAdvertiser } from '@client/bridges/DataLayer';

type AccountType = 'Professional' | 'Private';
export type Publisher = {
  type: 'Account';
  id: string;
  realm: 'subito';
  accountType: AccountType;
};

const isItemAdvertiser = (
  advertiser: ItemAdvertiser | DLAdvertiser
): advertiser is ItemAdvertiser => 'userId' in advertiser;

export default function transform(
  advertiser: DLAdvertiser | ItemAdvertiser
): Publisher {
  if (isItemAdvertiser(advertiser)) {
    return {
      type: 'Account',
      realm: 'subito',
      id: advertiser.userId,
      accountType:
        advertiser.type === AdvertiserType.ImpresaPiu
          ? 'Professional'
          : 'Private',
    };
  }

  return {
    type: 'Account',
    realm: 'subito',
    id: advertiser.id,
    accountType: advertiser.type === '1' ? 'Professional' : 'Private',
  };
}
