import { Component, type ReactNode, type PropsWithChildren } from 'react';

export class StaticWrapper extends Component<PropsWithChildren> {
  shouldComponentUpdate(): boolean {
    return false;
  }

  render(): ReactNode {
    return this.props.children;
  }
}
