import {
  AdItem,
  AnubisClient,
  HTTPStatusCode,
  SearchParams,
} from '@sbt-web/networking';
import { getClientsideEnvironmentId, loadedFromReload } from '@sbt-web/utils';
import { WEB_API_CHANNEL } from '@shared/constants';
import { isComingFromListingOrDetail } from '../utilities/referrer-check';
import {
  itemsKey,
  listingUrlKey,
  searchIdKey,
  searchParametersKey,
} from './constants';

interface SearchNavigationItem {
  /**
   * The URN of the ad
   */
  urn: string;
  /**
   * The URL to the ad
   */
  url: string;
}

interface SearchNavigation {
  /**
   * The 0-based index of the position of the first item in the total search result
   */
  from: number;
  items: SearchNavigationItem[];
  fetchedToTheEnd: boolean;
}

interface SearchNavigationDestinations {
  /**
   * The URL to return to the listing view of the current search
   */
  listingUrl: string | null;
}

/**
 * If the user has arrived on this detail from a listing or another detail,
 * pulls the various data required for the navigation and search logic out of
 * the local storage and saves them into the session storage.
 * If the user has arrived from any other origin, the navigation and search
 * data is deleted because the navigation cannot be related to a search.
 */
const syncStorage = (): void => {
  if (
    isComingFromListingOrDetail(window.document.referrer) ||
    loadedFromReload()
  ) {
    [itemsKey, listingUrlKey, searchParametersKey, searchIdKey].forEach(
      (key) => {
        if (!window.sessionStorage.getItem(key)) {
          const globalValue = window.localStorage.getItem(key);

          if (globalValue) {
            sessionStorage.setItem(key, globalValue);
          }
        }
      }
    );
  } else {
    [itemsKey, listingUrlKey, searchParametersKey, searchIdKey].forEach(
      (key) => {
        window.localStorage.removeItem(key);
        window.sessionStorage.removeItem(key);
      }
    );
  }
};

const getPageNumber = ({
  urnIndex,
  from,
  pageSize,
}: {
  urnIndex: number;
  from: number;
  pageSize: number;
}): number => Math.floor(urnIndex / pageSize) + Math.floor(from / pageSize) + 1;

const getBackUrl = (pageNumber: number): string | null => {
  const listingUrlValue = window.sessionStorage.getItem(listingUrlKey);

  if (!listingUrlValue) {
    return null;
  }
  const url = new URL(listingUrlValue);

  const pageNumberParameter = 'o';

  url.searchParams.set(pageNumberParameter, pageNumber.toString());

  return url.toString();
};

const needAnEarlierPage = (urnIndex: number, from: number): boolean =>
  urnIndex === 0 && from > 0;

const needALaterPage = (urnIndex: number, itemListLength: number): boolean =>
  urnIndex + 1 === itemListLength;

const shouldFetchAPage = (
  urnIndex: number,
  searchResult: SearchNavigation
): boolean =>
  needAnEarlierPage(urnIndex, searchResult.from) ||
  (!searchResult.fetchedToTheEnd &&
    needALaterPage(urnIndex, searchResult.items.length));

const fetchPage = async ({
  urnIndex,
  currentSearchResult,
  searchParams,
}: {
  urnIndex: number;
  currentSearchResult: SearchNavigation;
  searchParams: SearchParams;
}): Promise<void> => {
  const { from } = currentSearchResult;
  const nextPageParams = { ...searchParams };

  let fetchingForward = false;

  if (needAnEarlierPage(urnIndex, from)) {
    nextPageParams.start = from - (searchParams.lim || 0);
  } else {
    nextPageParams.start = from + (searchParams.lim || 0);
    fetchingForward = true;
  }

  const environmentId = getClientsideEnvironmentId();

  const anubiResult = await new AnubisClient(
    process.env.NEXT_PUBLIC_HADES_BASE_URL,
    WEB_API_CHANNEL,
    Number.parseInt(process.env.NEXT_PUBLIC_ANUBI_TIMEOUT_MS, 10)
  ).search(nextPageParams, undefined, environmentId || undefined);

  if (anubiResult.status !== HTTPStatusCode.OK) {
    throw new Error(
      `The Anubi query failed. The status is ${anubiResult.status} info is: ${anubiResult.info}`
    );
  }

  const fetchedItems: SearchNavigationItem[] = anubiResult.payload.ads.map(
    (ad: AdItem) => ({
      urn: ad.urn,
      url: ad.urls.default,
    })
  );

  const appendInOrder = (
    oldItems: SearchNavigationItem[],
    newItems: SearchNavigationItem[],
    isForward: boolean
  ): SearchNavigationItem[] => {
    if (isForward) {
      return [...oldItems, ...newItems];
    } else {
      return [...newItems, ...oldItems];
    }
  };

  const result: SearchNavigation = {
    items: appendInOrder(
      currentSearchResult.items,
      fetchedItems,
      fetchingForward
    ),
    from: Math.min(nextPageParams.start, from),
    fetchedToTheEnd:
      currentSearchResult.fetchedToTheEnd ||
      fetchedItems.length < (nextPageParams.lim || 0),
  };

  window.sessionStorage.setItem(itemsKey, JSON.stringify(result));
};

const defaultData = { listingUrl: null };

const getNavigationData = async (
  adUrn: string
): Promise<SearchNavigationDestinations> => {
  try {
    syncStorage();

    const searchResult: SearchNavigation = JSON.parse(
      window.sessionStorage.getItem(itemsKey) as string
    );

    const searchParameters: SearchParams = JSON.parse(
      window.sessionStorage.getItem(searchParametersKey) as string
    );

    if (!searchResult || !searchParameters) {
      return defaultData;
    }

    const urnIndex = searchResult.items.findIndex((u) => u.urn === adUrn);

    if (shouldFetchAPage(urnIndex, searchResult)) {
      try {
        await fetchPage({
          urnIndex,
          currentSearchResult: searchResult,
          searchParams: searchParameters,
        });

        return getNavigationData(adUrn);
      } catch (e) {
        console.groupCollapsed('Navigation data fetch error');
        console.error(e);
        console.groupEnd();
      }
    }

    const pageNumber = getPageNumber({
      urnIndex,
      from: searchResult.from,
      pageSize: searchParameters.lim || 0,
    });

    return { listingUrl: getBackUrl(pageNumber) };
  } catch (e) {
    console.error(e);
    return defaultData;
  }
};

/**
 * Gets the rank of an URN in the current search (its index in the overall search).
 * @param adUrn The urn of the ad to search for.
 * @returns Either a number representing the index of the urn, or null when the URN is not in the search results.
 */
const getUrnRank = (adUrn: string): number | null => {
  try {
    syncStorage();

    const searchResult: SearchNavigation = JSON.parse(
      window.sessionStorage.getItem(itemsKey) as string
    );

    if (searchResult == null) {
      return null;
    }

    const urnIndex = searchResult.items.findIndex((u) => u.urn === adUrn);

    if (urnIndex < 0) {
      return null;
    }

    const { from } = searchResult;

    return from + urnIndex;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getDetailSearchId = (): string | null => {
  if (__SERVER__) {
    return null;
  } else {
    try {
      syncStorage();
      return window.sessionStorage.getItem(searchIdKey);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};

export default getNavigationData;

export { getUrnRank, getDetailSearchId };
export type {
  SearchNavigationItem,
  SearchNavigation,
  SearchNavigationDestinations,
};
