import type { RadiusSearch } from '@reducers/search';

const transformToLocality = (data?: RadiusSearch): string | undefined => {
  if (data?.addressToTrack) {
    const { postalCode, city, state, countryName } = data.addressToTrack ?? {};

    const place = [postalCode, city, state, countryName]
      .filter((s) => s != undefined)
      .join('>');

    return `Radius_${data.radiusMeters}>${place}`;
  } else if (data?.radiusMeters) {
    return `Radius_${data.radiusMeters}`;
  }
};

const transformToLocations = (
  data?: RadiusSearch
): {
  '@type': 'PostalAddress';
  addressCountry: 'Italia';
  addressRegion?: string;
  addressLocality?: string;
  postalCode?: string;
  streetAddress?: string;
} => {
  if (data?.addressToTrack) {
    return {
      '@type': 'PostalAddress',
      addressCountry: 'Italia',
      addressRegion: data.addressToTrack?.state,
      addressLocality: data.addressToTrack?.city,
      postalCode: data.addressToTrack?.postalCode
        ? data.addressToTrack.postalCode
        : undefined,
      streetAddress:
        data.addressToTrack?.street != null ? 'ANONYMIZED' : undefined,
    };
  } else {
    return { '@type': 'PostalAddress', addressCountry: 'Italia' };
  }
};

const transformToGeolocations = (
  data?: RadiusSearch
): {
  '@type': 'GeoCoordinates';
  distanceFrom: number | undefined;
} => {
  return {
    '@type': 'GeoCoordinates',
    distanceFrom: data?.radiusMeters,
  };
};

export { transformToLocality, transformToLocations, transformToGeolocations };
