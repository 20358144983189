import {
  AdItem,
  AdType,
  CategoryId,
  GeoEntry,
  HebeCampaignPage,
  HebeCampaignPosition,
  HebeCampaignProperty,
} from '@sbt-web/networking';
import {
  parseAdDetailFeaturesForHebe,
  parseListingFiltersForHebe,
} from '@tools/search/hebeFiltersParser';
import { RemoteData } from '@sbt-web/ancillary-services';
import { HADES_PATH } from '@shared/constants';

export const prepareRemoteDataAdDetail = (
  position: HebeCampaignPosition,
  item: AdItem,
  isMobile: boolean
): RemoteData => ({
  baseUrl: HADES_PATH,
  body: {
    touchpoint: {
      page: HebeCampaignPage.ADDETAIL,
      position,
    },
    metadata: {
      ad_type: item.type.key,
      advertiser_id: item.advertiser?.userId,
      categories: [item.category.id],
      geo: {
        region: item.geo?.region?.id,
        city: item.geo?.city?.id,
        town: item.geo?.town?.id,
      },
      keywords: item.subject,
      property: isMobile
        ? HebeCampaignProperty.MSITE
        : HebeCampaignProperty.WEB,
    },
    filters: parseAdDetailFeaturesForHebe(item),
  },
});

export const prepareRemoteDataListing = (
  position: HebeCampaignPosition,
  ad_type: AdType,
  category: CategoryId,
  geo: GeoEntry,
  filters: Record<string, string>,
  query: string,
  isMobile: boolean
): RemoteData => ({
  baseUrl: HADES_PATH,
  body: {
    touchpoint: {
      page: HebeCampaignPage.LISTING,
      position,
    },
    metadata: {
      ad_type,
      categories: [category],
      geo: {
        ...(geo?.region?.id !== '0' && { region: geo?.region?.id }),
        city: geo?.city?.id,
        town: geo?.town?.id,
      },
      keywords: query,
      property: isMobile
        ? HebeCampaignProperty.MSITE
        : HebeCampaignProperty.WEB,
    },
    filters: parseListingFiltersForHebe(category, ad_type, filters),
  },
});
