import { Headline6, TextLink } from '@sbt-web/ui';
import React, { type FC } from 'react';
import classes from './Footer.module.scss';
import type { InternalLink } from './types';

interface FooterProps {
  links: InternalLink[];
}

const Footer: FC<FooterProps> = ({ links }) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <footer className={classes['container']}>
      <Headline6 classes={[classes['title']]}>Ricerche consigliate</Headline6>

      <div className={classes['links']}>
        {links.map((link) => (
          <TextLink
            key={link.href}
            href={link.href}
            weight="book"
            size="xs"
            themed={false}
            classes={[classes['link']]}
          >
            {link.label}
          </TextLink>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
