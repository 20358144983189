import React, { forwardRef, useEffect } from 'react';
import type { SmartADVItem } from '@shared/models';
import { CategoryId } from '@sbt-web/networking';
import { StaticWrapper } from '@client/components/Search/Elements/StaticWrapper';
import { shouldShowBigCards } from '@client/components/Search/Containers/AdItem';
import styles from './index.module.scss';
import { macroFromCategoryId } from '@shared/helpers/Themes';
import {
  fromMacroCategoryToVertical,
  smartInjectGAMPPlacement,
} from './helpers';
import { useIsEmptyAdSense } from '@client/components/Adv/AdSense/useIsEmptyAdSense';
import { AdSenseVariations } from '@client/components/Adv/AdSense';

function addSmartADVItem(
  item: SmartADVItem,
  listingCategory: CategoryId,
  isMobile: boolean
) {
  const config = isMobile ? item.msite : item.desktop;

  if (config === null) {
    return;
  }

  return (
    <StaticWrapper>
      {config.source === 'gam' ? (
        <div
          className={styles['smart-adv-gam']}
          style={config.style}
          data-cell-size={
            shouldShowBigCards(listingCategory) ? 'big' : 'normal'
          }
        >
          <div style={{ width: '100%' }} id={config.id} />
        </div>
      ) : (
        <div
          style={config.style}
          data-cell-size={
            shouldShowBigCards(listingCategory) ? 'big' : 'normal'
          }
        >
          <SmartAdSenseBlock
            variations={config.variations}
            listingCategory={listingCategory}
            fallbackAdUnitId={config.gamFallback}
          />
        </div>
      )}
    </StaticWrapper>
  );
}

type AdSenseBlockProps = {
  variations: AdSenseVariations;
  isMobile?: never;
};

type MultiPlatformAdSenseBlockProps = {
  desktop: AdSenseVariations;
  msite: AdSenseVariations;
  isMobile: boolean;
};
export type SmartAdSenseBlockProps = {
  listingCategory: CategoryId;
  fallbackAdUnitId?: string;
} & (AdSenseBlockProps | MultiPlatformAdSenseBlockProps);

const SmartAdSenseBlockPrivate = (
  { listingCategory, fallbackAdUnitId, ...props }: SmartAdSenseBlockProps,
  ref?: React.Ref<HTMLDivElement>
) => {
  const macroCategory = macroFromCategoryId(listingCategory).friendly;
  const variations =
    'isMobile' in props && typeof props.isMobile === 'boolean'
      ? props.isMobile
        ? props.msite
        : props.desktop
      : props.variations;

  const vertical = fromMacroCategoryToVertical(macroCategory);
  const tagId = variations[vertical];
  const isEmpty = useIsEmptyAdSense(tagId);

  useEffect(() => {
    if (isEmpty === true && fallbackAdUnitId) {
      smartInjectGAMPPlacement(fallbackAdUnitId);
    }
  }, [isEmpty, fallbackAdUnitId]);

  return (
    <>
      <div id={tagId} {...(ref ? { ref } : {})} />
      {isEmpty === true && fallbackAdUnitId ? (
        <div className={styles['smart-adv-adsense__fallback']}>
          <div
            id={fallbackAdUnitId}
            style={{
              width: '100%',
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const SmartAdSenseBlock = forwardRef<HTMLDivElement, SmartAdSenseBlockProps>(
  SmartAdSenseBlockPrivate
);

export { addSmartADVItem, SmartAdSenseBlock };
