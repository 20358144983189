import {
  getDynamicSizes,
  ScreenTarget,
  useScreenTarget,
} from './useScreenTarget';
import { useEffect, useState } from 'react';
import { useIsEmptyGptPlacement } from '@sbt-web/adv';

export type AdUnitSize = [number, number];
export type SkyscraperVisibility = 'visible' | 'hidden' | 'none';
export type SkyscraperData = {
  sizes?: Array<AdUnitSize>;
};

const SKIN_PLACEMENT_ID = 'apn_skin_tag';
const RIGHT_SKYSCRAPER = 'apn_skyscraper_tag_right';
const LEFT_SKYSCRAPER = 'apn_skyscraper_tag_left';

const usePolarisSkyscrapers = (enabled: boolean = true): SkyscraperData => {
  const [sizes, setSizes] = useState<undefined | Array<AdUnitSize>>();

  const skinStatus = useIsEmptyGptPlacement(SKIN_PLACEMENT_ID);
  const screenTarget = useScreenTarget();

  useEffect(() => {
    if (!sizes && screenTarget !== 'loading') {
      setSizes(
        screenTarget == ScreenTarget.TOO_SMALL
          ? []
          : getDynamicSizes(screenTarget)
      );
    }
  }, [screenTarget, sizes]);

  useEffect(() => {
    if (!enabled || !sizes || !sizes.length) {
      return;
    }
    if (skinStatus.loaded) {
      if (skinStatus.isEmpty) {
        window.subitoAdvQueue.push(() => {
          window.subitoAdv.gpt.injectFallbackAdUnits([
            RIGHT_SKYSCRAPER,
            LEFT_SKYSCRAPER,
          ]);
        });
      } else {
        window.subitoAdvQueue.push(() => {
          window.subitoAdv.gpt.destroySlots([
            RIGHT_SKYSCRAPER,
            LEFT_SKYSCRAPER,
          ]);
        });
      }
    }
  }, [skinStatus, sizes, enabled]);

  return {
    sizes,
  };
};

export { usePolarisSkyscrapers };
