import { CategoryStore } from '@sbt-web/networking';
import { Category, CategoryId, MacroCategory } from '@sbt-web/networking';

export function getCategoriesByMacro(category: string): Category[] {
  const cats = (CategoryStore.getCategoryByFriendly(category) as MacroCategory)
    .categories;

  if (!cats) {
    throw new Error(`${category} is not a macrocategory`);
  }

  return cats;
}

export function isCategoryInMacro(
  categoryId: CategoryId,
  macro: string
): boolean {
  return getCategoriesByMacro(macro).some((c) => c.id === categoryId);
}
