import type { AdsensePageOptions, AdsenseAdBlockOptions } from '@sbt-web/adv';
import type { AdSenseConfig } from '@client/components/Adv/AdSense/types';
import { notifyAdSenseLoadStatus } from '@client/components/Adv/AdSense/useIsEmptyAdSense';

export const runAdsense = function (
  query: string,
  categoryLabel: string,
  DOMQueryString: string,
  configs: { [key: string]: AdSenseConfig }
): void {
  const getValidDomElements = Array.from(
    document.querySelectorAll(DOMQueryString) ?? []
  );

  getValidDomElements.forEach((el) => {
    if (configs[el.id]) {
      const config = { ...configs[el.id] };
      const pageConfig: AdsensePageOptions = {
        ...config.pageOptions,
        query: config.generateQuery(query, categoryLabel),
      };
      const blockConfig: AdsenseAdBlockOptions = {
        ...config.adBlockOptions,
        adLoadedCallback: (containerName: string, adsLoaded: boolean) =>
          notifyAdSenseLoadStatus(containerName, !adsLoaded),
      };

      window.subitoAdvQueue.push(() => {
        window.subitoAdv.adSense.runAdsense(pageConfig, [blockConfig]);
      });
    }
  });
};
