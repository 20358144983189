import { Caption, Chip } from '@sbt-web/ui';
import React, { useEffect, useRef, useState, type FC } from 'react';
import type { InternalLink } from './types';

import classes from './Header.module.scss';

interface Props {
  links: InternalLink[];
}

const Header: FC<Props> = ({ links }) => {
  const [showToggleIcon, setShowToggleIcon] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const wrappedPotentialHeight = wrapperRef.current?.scrollHeight ?? 0;
      const wrapperDisplayedHeight = wrapperRef.current?.offsetHeight ?? 0;
      const areChipsBiggerThanContainer =
        wrappedPotentialHeight > wrapperDisplayedHeight;

      if (areChipsBiggerThanContainer) {
        setShowToggleIcon(true);
      } else {
        setShowToggleIcon(false);
        setIsOpen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkBoxValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isOpen = event.target.checked;
    setIsOpen(isOpen);
  };

  if (links.length === 0) {
    return null;
  }

  return (
    <div className={classes['wrapper']} ref={wrapperRef}>
      <Caption classes={[classes['title']]} size="small" weight="book">
        Ricerche <span className={classes['extra-label']}>&nbsp;simili</span>
      </Caption>
      <div className={classes['chips-wrapper']}>
        {links.map((link) => {
          return (
            <Chip
              classes={[classes['header-link']]}
              key={link.href}
              href={link.href}
            >
              {link.label}
            </Chip>
          );
        })}
      </div>

      {showToggleIcon ? (
        <div className={classes['toggle-icon']}>
          <label
            data-testid="internal-links-header-toggle"
            aria-expanded={isOpen}
            className={classes['toggle-triangle']}
          >
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/cactus/arrow-down.svg`}
              alt={`Vedi ${isOpen ? 'meno' : 'più'} richerche`}
              height="16"
              width="16"
            />
            <input
              type="checkbox"
              name="isOpen"
              id={classes['toggle-checkbox']}
              onChange={checkBoxValueChanged}
              checked={isOpen}
            />
          </label>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
