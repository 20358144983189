import { getPublicUser } from '@sbt-web/auth';
import { presencePing, type PresencePingParams } from '@sbt-web/presence-ping';

export const initPresencePing = (checkSetting?: boolean): void => {
  const user = getPublicUser();

  if (!user?.id) {
    return;
  }

  const presenceParams: PresencePingParams = {
    presenceApiUrl: process.env.NEXT_PUBLIC_TRUST_PRESENCE_BASE_URL,
    profileApiUrl: process.env.NEXT_PUBLIC_TRUST_PROFILE_BASE_URL,
    hadesPublicBase: process.env.NEXT_PUBLIC_HADES_BASE_URL,
    userId: user?.id,
    intervalTime: 3000,
    checkSetting: checkSetting ?? false,
  };

  presencePing(presenceParams);
};
