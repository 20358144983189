import { buildSearchWithRank } from '@tools/tracking/transformer/SearchTransformer';
import { CategoryStore } from '@sbt-web/networking';
import { slugify } from '@sbt-web/utils';
import { ItemCategory } from '@sbt-web/networking';

interface TrackingItem {
  adItemUrn: string;
  adItemSubject: string;
  adItemCategory: ItemCategory;
  searchId: string;
  adItemRank: number;
  adId: number;
}

interface TrackingData {
  type: string;
  object: {
    type: string;
    id: string;
    name: string;
    category: string;
    adId: number;
  };
  search: {
    '@id': string;
    '@type': 'SearchMetadata';
    source: 'MainSearch';
    rank: number;
  };
}

export function transformCategory(category: ItemCategory): string {
  if (category.parentId === '0') {
    return '';
  } else if (category.parentId === '27') {
    return `altro>${slugify(category.label)}`;
  } else if (category.parentId === category.id) {
    return slugify(category.label);
  }

  return `${
    CategoryStore.getMacroByCategoryId(category.id)?.friendly
  }>${slugify(category.label)}`;
}

export const buildCardImpressionTrackingViewData = (
  adItem: TrackingItem
): TrackingData => {
  const {
    adItemUrn,
    adItemSubject,
    adItemCategory,
    searchId,
    adItemRank,
    adId,
  } = adItem;

  return {
    type: 'Watch',
    object: {
      type: 'ClassifiedAd',
      id: adItemUrn,
      name: adItemSubject,
      category: transformCategory(adItemCategory),
      adId,
    },
    search: buildSearchWithRank(searchId, adItemRank),
  };
};
