import type { DLAdType } from '@client/bridges/DataLayer';

export default function transform(rawAdType: DLAdType | null): string {
  const map = {
    s: 'sell',
    k: 'buy',
    u: 'rent',
    h: 'give',
    g: 'gift',
  };

  return (
    (rawAdType != null &&
      'id' in rawAdType &&
      map[rawAdType.id as keyof typeof map]) ||
    ''
  );
}
