import { useEffect, useState } from 'react';
import { PolarisSkyscrapes } from '@sbt-web/adv';

enum ScreenTarget {
  TOO_SMALL = -1,
  S = 0,
  M = 1,
  L = 2,
}

const MAIN_WIDTH_PX = 980;
const INNER_MARGIN_PX = 12;
const ScreenTargetBreakpoints: Map<ScreenTarget, string> = new Map([
  [
    ScreenTarget.S,
    `(min-width: ${MAIN_WIDTH_PX + 2 * (120 + INNER_MARGIN_PX)}px)`,
  ],
  [
    ScreenTarget.M,
    `(min-width: ${MAIN_WIDTH_PX + 2 * (160 + INNER_MARGIN_PX)}px)`,
  ],
  [
    ScreenTarget.L,
    `(min-width: ${MAIN_WIDTH_PX + 2 * (300 + INNER_MARGIN_PX)}px)`,
  ],
]);

const getDynamicSizes = (target: ScreenTarget): PolarisSkyscrapes => [
  ...(target >= ScreenTarget.L ? ([[300, 600]] as PolarisSkyscrapes) : []),
  ...(target >= ScreenTarget.M ? ([[160, 600]] as PolarisSkyscrapes) : []),
  ...(target >= ScreenTarget.S ? ([[120, 600]] as PolarisSkyscrapes) : []),
];

const useScreenTarget = (): ScreenTarget | 'loading' => {
  const [screenTarget, setScreenTarget] = useState<ScreenTarget | 'loading'>(
    'loading'
  );

  // Updates the screen target
  useEffect(() => {
    const matchingTarget = [
      ScreenTarget.L,
      ScreenTarget.M,
      ScreenTarget.S,
    ].find(
      (target: ScreenTarget) =>
        window.matchMedia(<string>ScreenTargetBreakpoints.get(target)).matches
    );

    setScreenTarget(matchingTarget ?? ScreenTarget.TOO_SMALL);
  }, []);

  return screenTarget;
};

export {
  useScreenTarget,
  ScreenTarget,
  getDynamicSizes,
  ScreenTargetBreakpoints,
};
