import qualitySeals from '@client/components/Detail/QualitySeal/quality-seals';
import type { RootState } from '@reducers/index';
import { mapSearchStateToSearchParams, type ItemsState } from '@reducers/items';
import type { SearchState } from '@reducers/search';
import { useIsVisible } from '@sbt-web/hooks';
import ItemCard from '@sbt-web/item-card';
import { CategoryId, FeatureURI, type AdItem } from '@sbt-web/networking';
import { urnTransformer } from '@sbt-web/utils';
import { HADES_PATH } from '@shared/constants';
import { isCategoryInMacro } from '@shared/models/Categories';
import { buildCardImpressionTrackingViewData } from '@tools/tracking/adsListingImpressionsTracking/buildCardImpressionTrackingData';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React from 'react';
import { connect } from 'react-redux';

import '@sbt-web/item-card/dist/index.css';
import { captureException } from '@sentry/nextjs';

interface StateProps {
  search?: { id: string; rank?: number };
  searchingInDialog: boolean;
  adId: number;
}

interface OwnProps {
  index: number;
  item: AdItem;
  listingCategory: CategoryId;
  smallCardImageVariant: 'normal' | 'large';
  hideImage: boolean;
  handleItemClick?: () => void;
}

type Props = OwnProps & StateProps;

export function AdItemComponent({
  index,
  item,
  listingCategory,
  search,
  searchingInDialog,
  adId,
  smallCardImageVariant,
  hideImage,
  handleItemClick,
}: Props) {
  const bigCard = shouldShowBigCards(listingCategory);

  const itemCardRef = React.useRef(null);
  const options = {
    threshold: 0.5,
  };

  const [isItemCardVisible, setItemCardRef, unobserveItemCard] = useIsVisible({
    observerOptions: options,
  });

  React.useEffect(() => {
    if (itemCardRef?.current) {
      // Set target to be observed by the IntersectionObserver
      setItemCardRef(itemCardRef.current);

      // Send tracking when a card is in the obersver threshold, search id exists and mobile dialog is closed.
      if (isItemCardVisible && search?.id !== undefined && !searchingInDialog) {
        // Send tracking Ads Impressions to Pulse
        getOrCreatePulse()?.queueEvent(
          buildCardImpressionTrackingViewData({
            adItemUrn: item.urn,
            adItemSubject: item.subject,
            adItemCategory: item.category,
            searchId: search.id,
            // -1 is a failsafe and shouldn't really ever end up in use
            adItemRank: search.rank ?? -1,
            adId,
          })
        );
        unobserveItemCard();
      }
    }

    // Does not include search and searchingInDialog in useEffect's dependencies to avoid sending trackings when only search id or dialogOpen state changes.
    // Send trackings when listing is updated with new items having new search id and dialogOpen status closed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemCardVisible, setItemCardRef, unobserveItemCard, item]);

  // A temporary fix to overwrite the name coming from Anubi with the name we
  // have locally: there is an encoding issue on non-ASCII characters.
  try {
    if (item.features[FeatureURI.QualitySeal] !== undefined) {
      const qsId = item.features[FeatureURI.QualitySeal].values[0].key;

      const localName = qualitySeals[qsId]?.name;

      if (localName !== undefined) {
        item.features[FeatureURI.QualitySeal].values[0].value = localName;
      }
    }
  } catch {
    captureException(
      new Error('Error while trying to overwrite the name of the quality seal')
    );
  }

  return (
    <ItemCard
      item={item}
      type={bigCard ? 'big' : 'small'}
      apiBase={HADES_PATH}
      impresapiuBase={process.env.NEXT_PUBLIC_IMPRESAPIU_BASE_URL}
      assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
      search={search}
      lazyLoad={bigCard ? index >= 2 : index >= 4}
      ref={itemCardRef}
      pulseInstance={getOrCreatePulse()}
      imageVariant={smallCardImageVariant}
      suppressGallery={hideImage}
      onClick={handleItemClick}
    />
  );
}

export function shouldShowBigCards(category: CategoryId) {
  return (
    category === CategoryId.Auto ||
    category === CategoryId.Immobili ||
    isCategoryInMacro(category, 'immobili')
  );
}

const adRank = (urn: string, search: SearchState, items: ItemsState) => {
  const { start = 0 } = mapSearchStateToSearchParams(search);
  const index = items.list.findIndex((listAd) => listAd.item.urn === urn);
  return start + index;
};

function mapStateToProps(state: RootState, ownProps: OwnProps): Props {
  // 0 is a failsafe and shouldn't really ever end up in use
  const { adId } = urnTransformer(ownProps.item.urn) ?? { adId: '0' };
  const props: Props = {
    ...ownProps,
    searchingInDialog:
      state.search.filtersDialogOpen || state.search.radiusDialogOpen,
    adId: Number.parseInt(adId),
  };

  const searchId = state.search.id;
  if (searchId) {
    props.search = {
      id: searchId,
      rank: adRank(ownProps.item.urn, state.search, state.items),
    };
  }

  return props;
}

export default connect<Props, void, OwnProps, RootState>(mapStateToProps)(
  AdItemComponent
);
