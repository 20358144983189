interface QualitySeal {
  id: string;
  name: string;
  brand: string;
  external_url: string;
  youtube_id?: string;
  logoName?: string;
}

const qualitySeals: Record<string, QualitySeal> = {
  QS001: {
    id: 'QS001',
    name: 'Audi Prima Scelta :plus',
    brand: '000161',
    external_url: 'https://www.audi.it/it/web/it/usato.html',
    youtube_id: 'jwp8-lxcy74',
    logoName: 'audi-prima-scelta-plus.png',
  },
  QS002: {
    id: 'QS002',
    name: 'BMW Premium Selection',
    brand: '000085',
    external_url:
      'https://www.bmw.it/it/fastlane/usato-bmw/bmw-premium-selection/garanzie-auto-usate.html',
    youtube_id: 'GxpEQ0m9xbE',
    logoName: 'bmw-premium-selection.png',
  },
  QS003: {
    id: 'QS003',
    name: 'Citroen Select',
    brand: '000091',
    external_url: 'https://www.citroenselect.it/impegni-citroen',
    logoName: 'citroen-select.png',
  },
  QS004: {
    id: 'QS004',
    name: 'Volkswagen Usato Certificato',
    brand: '000101',
    external_url: 'https://usato.volkswagen.it/promozioni/usato-certificato',
    logoName: 'vw-usato-certificato.png',
  },
  QS005: {
    id: 'QS005',
    name: 'Hyundai i-Best',
    brand: '000122',
    external_url: 'https://www.hyundai.it/',
    logoName: 'hyundai-i-best.png',
  },
  QS006: {
    id: 'QS006',
    name: 'Jaguar Approved',
    brand: '000110',
    external_url:
      'https://www.jaguarapproved.it/approvedbenefits?gclid=CNnZ-qS4utICFUMcGwodfXQOPQ&dclid=CI6kkqW4utICFXYw0wodtzoJcw',
    logoName: 'jaguar-approved.png',
  },
  QS007: {
    id: 'QS007',
    name: 'Kia Usato',
    brand: '000746',
    external_url: 'https://www.kia.com/it/usato/programma-kia-usato/',
    logoName: 'kia-usato.png',
  },
  QS008: {
    id: 'QS008',
    name: 'Land Rover Approved',
    brand: '000140',
    external_url:
      'https://www.landroverapproved.it/approvedbenefits?gclid=CI6FhJO6utICFdU_Gwodn5AMkg&dclid=COvJq5O6utICFYqjUQodKCIAVQ',
    logoName: 'land-rover-approved.png',
  },
  QS009: {
    id: 'QS009',
    name: 'Maserati Pre-Owned',
    brand: '000117',
    external_url: 'https://www.maserati.com/ch/it/services/pre-owned',
    logoName: 'maserati-pre-owned.png',
  },
  QS010: {
    id: 'QS010',
    name: 'Mercedes-Benz FirstHand',
    brand: '000033',
    external_url:
      'https://www.mercedes-benz.it/passengercars/buy/used-cars-certified/stage.module.html',
    logoName: 'mercedes-benz-firsthand.png',
  },
  QS011: {
    id: 'QS011',
    name: 'Smart FirstHand',
    brand: '002259',
    external_url: 'https://smartcertified.it/auto-usate-servizi/',
  },
  QS012: {
    id: 'QS012',
    name: 'MINI Next',
    brand: '002222',
    external_url: 'https://www.mini.it/it_IT/home/used/mini-next.html',
    youtube_id: '2v78PjG4aLs',
    logoName: 'mini-next.png',
  },
  QS013: {
    id: 'QS013',
    name: 'Peugeot Occasioni',
    brand: '000151',
    external_url: 'https://www.peugeotoccasioni.it/impegni-peugeot',
    logoName: 'peugeot-occasioni.png',
  },
  QS014: {
    id: 'QS014',
    name: 'Porsche Approved',
    brand: '000027',
    external_url: 'https://www.porsche.com/italy/approvedused/porscheapproved/',
    logoName: 'porsche-approved.png',
  },
  QS015: {
    id: 'QS015',
    name: 'Renault Sélection',
    brand: '000062',
    external_url: 'https://veicoli-occasione.renault.it/i-nostri-impegni.html',
    logoName: 'renault-selection.png',
  },
  QS016: {
    id: 'QS016',
    name: 'Volvo Selekt Auto',
    brand: '000098',
    external_url: 'https://selekt.volvocars.it/it/volvo-selekt',
    logoName: 'volvo-selekt-auto.png',
  },
  QS017: {
    id: 'QS017',
    name: 'Ford Approved',
    brand: '000039',
    external_url:
      'https://www.ford.it/guida-allacquisto/scegli-la-tua-ford/usato-certificato',
    logoName: 'ford-approved.png',
  },
  QS018: {
    id: 'QS018',
    name: 'Fiat Group Autoexpert',
    brand: '000008',
    external_url: 'https://www.fiat.it/auto-usate#!/search?adobe_mc_ref=',
    logoName: 'fiat-group-autoexpert.png',
  },
  QS019: {
    id: 'QS019',
    name: 'Ferrari Approved',
    brand: '000013',
    external_url: 'https://preowned.ferrari.com/it',
    logoName: 'ferrari-approved.png',
  },
  QS020: {
    id: 'QS020',
    name: 'Toyota Approved',
    brand: '000103',
    external_url: 'https://www.toyota.it/toyota-approved',
    logoName: 'toyota-approved.png',
  },
  QS021: {
    id: 'QS021',
    name: 'Selezione Arval',
    brand: '000000',
    external_url: 'https://www.arval.it/usato-arval/privato',
    youtube_id: 'c_dbJ8VJeRA',
    logoName: 'selezione-arval.png',
  },
  QS022: {
    id: 'QS022',
    name: 'Spoticar',
    brand: '000000',
    external_url: 'https://www.spoticar.it/',
    logoName: 'spoticar.svg',
  },
  QS023: {
    id: 'QS023',
    name: 'Lexus Select',
    brand: '000000',
    external_url: 'https://www.lexus.it/lexus-select',
    youtube_id: 'iE5uBjkH1qQ',
    logoName: 'lexus-select.png',
  },
};

export default qualitySeals;
