import { CategoryStore } from '@sbt-web/networking';

// This regex matches every subito subdomain for subito, regress and piutardi
// Additionally, it captures the first part of the pathname, e.g.
// For https://www.subito.it/libri-riviste/some-ad-121212.htm it will yield
// libri-riviste in the first capturing group
const regex =
  /^https?:\/\/\w+\.(?:(?:regress)?subito|piutardi)\.it(?::\d+)?\/?([\w-]*)?/;

const isComingFromSubito = (referrer: string): boolean => {
  if (!referrer) {
    return false;
  }

  return regex.test(referrer);
};

const isComingFromHomepage = (referrer: string): boolean => {
  if (isComingFromSubito(referrer)) {
    const match = regex.exec(referrer);

    return (match && match[1] == null) || false;
  } else {
    return false;
  }
};

const isComingFromDetail = (referrer: string): boolean => {
  if (isComingFromSubito(referrer)) {
    const match = regex.exec(referrer);

    const categorySlugs = CategoryStore.getAllCategories().map(
      (c) => c.friendly
    );

    return (
      (match && Boolean(match?.[1]) && categorySlugs.includes(match[1])) ||
      false
    );
  } else {
    return false;
  }
};

const isComingFromListing = (referrer: string): boolean => {
  if (isComingFromSubito(referrer)) {
    const match = regex.exec(referrer);

    if (match?.[1]) {
      const slug = match[1];

      const annunciSlug = slug.startsWith('annunci');
      const otherAdsSlug = slug === 'annunci-vari';

      return annunciSlug && !otherAdsSlug;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isComingFromListingOrDetail = (referrer: string): boolean =>
  isComingFromListing(referrer) || isComingFromDetail(referrer);

export {
  isComingFromHomepage,
  isComingFromDetail,
  isComingFromListing,
  isComingFromListingOrDetail,
};
