import type { DLGeo } from '@client/bridges/DataLayer';
import { slugify } from '@sbt-web/utils';

export default function transform(geo: DLGeo): string | undefined {
  if (geo.city && geo.town) {
    return `${slugify(geo.city.name)}>${slugify(geo.town.name)}`;
  } else if (geo.city) {
    return slugify(geo.city.name);
  } else {
    return undefined;
  }
}
